import React, { useEffect } from "react"
import DOMPurify from "isomorphic-dompurify"
import { servicesAnalyticsClick } from "@/components/Shared/HeaderNavTabs/analytics"
import SmartCropImage from "@/components/core/SmartCropImage"
import Image from "next/image"
import UpAccordion from "@/public/icons/up-accordion.svg"
import DownAccordion from "@/public/icons/down-accordion.svg"
import NavigationAccordion from "@/components/Shared/HeaderNavTabs/NavigationAccordion/index"

const TabPanel = props => {
  const {
    element,
    selectedId,
    levelOneLengthsArray,
    navOpen,
    selectedNavTabIndex,
    navTabIndex,
    setActiveIndex,
    setSelectedId,
    selectedTab,
    item,
    staticClasses,
    smartCrop,
    darkThemeFlag,
    handleAccordionClick,
    getInternalLinkURLS,
    menuType,
    onClickNav,
    activeIndex,
  } = props

  const caretimg = "/icons/bold.svg"
  const externalicon = "/icons/externalicon.svg"
  const caretimgWhite = "/icons/bold-white.svg"
  const externalIconWhite = "/icons/external link.svg"

  useEffect(() => {
    if (levelOneLengthsArray[selectedNavTabIndex] !== -1) {
      setActiveIndex(levelOneLengthsArray[selectedNavTabIndex])
      setSelectedId(levelOneLengthsArray[selectedNavTabIndex])
    }
  }, [])

  return (
    <div
      key={`navTabs-${navTabIndex}`}
      role="tabpanel"
      tabIndex="-1"
      className={`cmp-tabs__tabpanel ${
        navOpen === element["cq:panelTitle"] ? "cmp-tabs__tabpanel--active" : ""
      }`}
    >
      <div className={`${staticClasses.CONTAINER}`}>
        <div className="cmp-container">
          <div className="aem-Grid aem-Grid--11 aem-Grid--default--11 ">
            <div className="globalnavigation aem-GridColumn aem-GridColumn--default--11">
              <div className="gbh-full-w-menu">
                <div className="bg-dim">
                  <div className="gbh-full-w-menu__inner" id="Mouse id">
                    <div className="gbh-full-max-width">
                      <div
                        className={`menu-container ${
                          darkThemeFlag ? "dark-theme" : "light-theme"
                        } ${menuType}`}
                      >
                        <div
                          className={`gbh-full-w-menu__left-cont ${menuType}`}
                        >
                          <div className="gbh-full-w-menu__left-cont__top">
                            <div className="levelZero">
                              <ul className="nav flex-column gbh-full-w-menu__left-cont__menu-list">
                                <button
                                  className="gbh-mob-back-btn"
                                  onClick={() =>
                                    onClickNav({
                                      "cq:panelTitle": navOpen,
                                    })
                                  }
                                >
                                  {selectedTab}
                                </button>
                                <div className="level-one-container">
                                  <div className="level-one-sub-container">
                                    {item?.navTitle ? (
                                      <div className="group-title">
                                        {item?.navTitle}
                                      </div>
                                    ) : null}

                                    <div className="level-one-nav-multi">
                                      {item?.levelOneNavMulti?.map(
                                        (navItem, id) => {
                                          return (
                                            <div
                                              key={navItem + id}
                                              className={`levelTwoMapping ${
                                                menuType +
                                                (item?.levelOneNavMulti
                                                  ?.length === 1
                                                  ? "-one-col"
                                                  : "")
                                              }`}
                                            >
                                              {navItem?.levelTwoNavMulti?.map(
                                                (levelTwo, index) => {
                                                  return (
                                                    <li
                                                      key={navItem + index}
                                                      className={
                                                        levelTwo?.groupTitle?.includes(
                                                          "carrot-icon"
                                                        )
                                                          ? "carot-title"
                                                          : activeIndex ===
                                                              index &&
                                                            selectedId === id &&
                                                            levelTwo
                                                              ?.levelThreeNavMulti
                                                              ?.length > 0 &&
                                                            navTabIndex !== null
                                                          ? "tile-bg"
                                                          : ""
                                                      }
                                                    >
                                                      <div
                                                        onClick={() =>
                                                          handleAccordionClick(
                                                            index,
                                                            id,
                                                            navTabIndex
                                                          )
                                                        }
                                                        key={index}
                                                        className="menu-item-tiles"
                                                      >
                                                        {levelTwo
                                                          ?.levelThreeNavMulti
                                                          ?.length > 0 ? (
                                                          <div
                                                            className={`menuItems ${menuType} ${
                                                              levelTwo?.groupTitle?.includes(
                                                                "carrot-icon"
                                                              )
                                                                ? "carot-icon"
                                                                : ""
                                                            }`}
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                DOMPurify.sanitize(
                                                                  levelTwo?.groupTitle
                                                                ),
                                                            }}
                                                          />
                                                        ) : (
                                                          <a
                                                            className={`menuItems ${menuType} ${
                                                              levelTwo?.groupTitle?.includes(
                                                                "carrot-icon"
                                                              )
                                                                ? "carot-icon"
                                                                : ""
                                                            }`}
                                                            href={levelTwo?.groupLink
                                                              ?.split(
                                                                process.env
                                                                  .NEXT_PUBLIC_INTERNALLINKPATH
                                                              )
                                                              .join("")
                                                              .replace(
                                                                ".html",
                                                                ""
                                                              )}
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                DOMPurify.sanitize(
                                                                  levelTwo?.groupTitle
                                                                ),
                                                            }}
                                                            onClick={() =>
                                                              servicesAnalyticsClick(
                                                                {
                                                                  text: levelTwo?.groupTitle,
                                                                  link: levelTwo?.groupLink,
                                                                  Leveltwo:
                                                                    selectedTab,
                                                                }
                                                              )
                                                            }
                                                          />
                                                        )}
                                                        {levelTwo
                                                          ?.levelThreeNavMulti
                                                          .length > 0 ? (
                                                          <Image
                                                            src={
                                                              activeIndex ===
                                                                index &&
                                                              selectedId ===
                                                                id &&
                                                              navTabIndex !==
                                                                null
                                                                ? UpAccordion
                                                                : DownAccordion
                                                            }
                                                            alt="down-accordion icon"
                                                            className="toggleArrow"
                                                          />
                                                        ) : null}
                                                        {levelTwo?.groupTitle?.includes(
                                                          "carrot-icon"
                                                        ) ? (
                                                          <div className="navigation-img">
                                                            <img
                                                              src={
                                                                darkThemeFlag
                                                                  ? caretimgWhite
                                                                  : caretimg
                                                              }
                                                              alt="caret-icon"
                                                            />
                                                          </div>
                                                        ) : null}
                                                        {getInternalLinkURLS(
                                                          levelTwo?.groupLink
                                                        ) ? (
                                                          <div className="navigation-external-img">
                                                            <img
                                                              src={
                                                                darkThemeFlag
                                                                  ? externalIconWhite
                                                                  : externalicon
                                                              }
                                                              alt="external-icon"
                                                            />
                                                          </div>
                                                        ) : null}
                                                      </div>

                                                      {activeIndex === index &&
                                                      selectedId === id &&
                                                      navTabIndex !== null ? (
                                                        <NavigationAccordion
                                                          darkThemeFlag={
                                                            darkThemeFlag
                                                          }
                                                          levelTwo={levelTwo}
                                                          getInternalLinkURLS={
                                                            getInternalLinkURLS
                                                          }
                                                        />
                                                      ) : null}
                                                    </li>
                                                  )
                                                }
                                              )}
                                            </div>
                                          )
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="gbh-full-w-menu__right-img">
                          {item?.multiCTA?.length > 0 ? (
                            <div className="right-img-cta">
                              {item?.multiCTA?.map((ctaItem, index) => {
                                return (
                                  <ol
                                    key={`navItem-${index}`}
                                    className="multicta"
                                  >
                                    <div className="navigationcta">
                                      <div className="ctalable">
                                        <a
                                          rel="noreferrer"
                                          target={
                                            ctaItem?.newTabCTA
                                              ? "_blank"
                                              : "_self"
                                          }
                                          href={ctaItem?.ctaLink
                                            ?.split(
                                              process.env
                                                .NEXT_PUBLIC_INTERNALLINKPATH
                                            )
                                            .join("")
                                            .replace(".html", "")}
                                        >
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: DOMPurify.sanitize(
                                                ctaItem.ctaLabel
                                              ),
                                            }}
                                          />
                                        </a>
                                      </div>
                                      <div className="cta-img">
                                        <img
                                          src={
                                            darkThemeFlag
                                              ? caretimgWhite
                                              : caretimg
                                          }
                                          alt="caret-icon"
                                        />
                                      </div>
                                    </div>
                                  </ol>
                                )
                              })}
                            </div>
                          ) : null}

                          <div className={`block-images ${menuType}`}>
                            {item?.firstImagesblock ? (
                              <div
                                className={`${element?.appliedCssClassNames} ${menuType}`}
                              >
                                <a
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  target={
                                    item?.firstImagesblock?.newTabFirstImage
                                      ? "_blank"
                                      : "_self"
                                  }
                                  href={item?.firstImagesblock?.firstImageLink
                                    ?.split(
                                      process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                    )
                                    .join("")
                                    .replace(".html", "")}
                                  rel="noreferrer"
                                >
                                  <SmartCropImage
                                    url={
                                      item?.firstImagesblock
                                        ?.firstImageFileReference
                                    }
                                    className="cmp-image__image"
                                    smartCrop={smartCrop}
                                    altText={
                                      item?.firstImagesblock?.altText ?? ""
                                    }
                                  />
                                </a>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      item?.firstImagesblock?.firstImageTitle
                                    ),
                                  }}
                                />
                                {item?.firstImagesblock?.ctaLink ? (
                                  <div className="image-cta">
                                    <div className="div-of-cta">
                                      <a
                                        href={item?.firstImagesblock?.ctaLink
                                          ?.split(
                                            process.env
                                              .NEXT_PUBLIC_INTERNALLINKPATH
                                          )
                                          .join("")
                                          .replace(".html", "")}
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            item?.firstImagesblock?.ctaLabel
                                          ),
                                        }}
                                      ></a>
                                    </div>
                                    <div className="cta-link-caret">
                                      <img
                                        src={
                                          darkThemeFlag
                                            ? caretimgWhite
                                            : caretimg
                                        }
                                        alt="caret-icon"
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                            {item?.secondImagesblock &&
                            item?.levelOneNavMulti?.length < 3 ? (
                              <div
                                className={`${element?.appliedCssClassNames} ${menuType}`}
                              >
                                <a
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  target={
                                    item?.secondImagesblock?.newTabSecondImage
                                      ? "_blank"
                                      : "_self"
                                  }
                                  href={item?.secondImagesblock?.secondImageLink
                                    ?.split(
                                      process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                    )
                                    .join("")
                                    .replace(".html", "")}
                                  rel="noreferrer"
                                >
                                  <SmartCropImage
                                    url={
                                      item?.secondImagesblock
                                        ?.secondImageFileReference
                                    }
                                    className="cmp-image__image"
                                    smartCrop={smartCrop}
                                    altText={
                                      item?.secondImagesblock?.altText ?? ""
                                    }
                                  />
                                </a>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      item?.secondImagesblock?.secondImageTitle
                                    ),
                                  }}
                                />
                                {item?.secondImagesblock?.ctaLink ? (
                                  <div className="image-cta">
                                    <div className="div-of-cta">
                                      <a
                                        href={item?.secondImagesblock?.ctaLink
                                          ?.split(
                                            process.env
                                              .NEXT_PUBLIC_INTERNALLINKPATH
                                          )
                                          .join("")
                                          .replace(".html", "")}
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            item?.secondImagesblock?.ctaLabel
                                          ),
                                        }}
                                      ></a>
                                    </div>
                                    <div className="cta-link-caret">
                                      <img
                                        src={
                                          darkThemeFlag
                                            ? caretimgWhite
                                            : caretimg
                                        }
                                        alt="caret-icon"
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="productbg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabPanel
