import MenuItem from "@/components/Shared/HeaderNavTabs/MenuItem/index"

const NavigationAccordion = props => {
  const {
    levelTwo: {
      groupLink = "",
      mobileLabel = "",
      levelThreeNavMulti = [],
    } = {},
    getInternalLinkURLS = () => {},
    darkThemeFlag = false,
  } = props

  return (
    <>
      {groupLink && mobileLabel ? (
        <MenuItem
          getInternalLinkURLS={getInternalLinkURLS}
          itemLink={groupLink}
          itemLabel={mobileLabel}
          darkThemeFlag={darkThemeFlag}
        />
      ) : null}
      {levelThreeNavMulti?.map((columnItems, index) => {
        const { link = "", label = "" } = columnItems
        return (
          <div key={label + index}>
            <MenuItem
              getInternalLinkURLS={getInternalLinkURLS}
              itemLink={link}
              itemLabel={label}
              darkThemeFlag={darkThemeFlag}
            />
          </div>
        )
      })}
    </>
  )
}

export default NavigationAccordion
