import Link from "next/link"
import { useEffect } from "react"
import brandLogo from "@/public/icons/BLOK_LOGO.svg"

const HeaderNavLogo = props => {
  const {
    data = {},
    columnClassNames = "",
    eventHandler: {
      store: { brandLogoImg = "" } = {},
      callbacks: { setBrandLogoImg = () => {}, toggleMenu = () => {} },
    },
    v3,
  } = props
  const hamburgericon = "/icons/icon-Hamburger.svg"

  useEffect(() => {
    setBrandLogoImg(brandLogo?.src)
  }, [])

  return (
    <>
      <span
        className="gbh-hamber-menu icon-Hamburger"
        tabIndex="0"
        aria-label="Hamburger-menu"
        role="button"
        onClick={toggleMenu}
      >
        {v3 ? <img src={hamburgericon} alt="" /> : null}
      </span>
      <div
        role="img"
        aria-label="The Bold Look of Kohler logo"
        tabIndex="0"
        className={`cmp cmp-image ${
          data?.appliedCssClassNames ? data?.appliedCssClassNames : ""
        } ${columnClassNames ? columnClassNames : ""}`}
      >
        <Link
          tabIndex="-1"
          href={data?.link
            ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
            .join("")
            .replace(".html", "")}
          title={data?.alt}
          className="cmp-image--link"
        >
          <section>
            {brandLogoImg && <img src={`${brandLogoImg}`} alt={data?.alt} />}
          </section>
        </Link>
      </div>
    </>
  )
}

export default HeaderNavLogo
