import React from "react"
import DOMPurify from "isomorphic-dompurify"
import { previewContentPrefix } from "@/constants"

const externalicon = "/icons/externalicon.svg"
const externalIconWhite = "/icons/external link.svg"

const MenuItem = props => {
  const {
    getInternalLinkURLS = () => {},
    itemLink = "",
    itemLabel = "",
    darkThemeFlag = false,
  } = props

  return (
    <li key={itemLabel + itemLink} className="nav-item">
      <a
        href={itemLink
          ?.split(previewContentPrefix)
          .join("")
          .replace(".html", "")}
      >
        <span
          className="menu-labels"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(itemLabel),
          }}
        />
        {getInternalLinkURLS(itemLink) ? (
          <div className="navigation-external-img">
            <img
              src={darkThemeFlag ? externalIconWhite : externalicon}
              alt="external-icon"
            />
          </div>
        ) : null}
      </a>
    </li>
  )
}
export default MenuItem
