export const checkSingleNonEmptyLevelThreeNavMulti = data => {
  let nonEmptyLevelThreeNavMultiIndex = -1
  let nonEmptyLevelThreeNavMultiCount = 0

  for (let i = 0; i < data.length; i++) {
    for (const levelTwoNavMulti of data[i].levelTwoNavMulti) {
      if (levelTwoNavMulti.levelThreeNavMulti.length >= 1) {
        nonEmptyLevelThreeNavMultiCount++
        if (nonEmptyLevelThreeNavMultiCount > 1) {
          return -1 // More than one non-empty levelThreeNavMulti found
        } else {
          nonEmptyLevelThreeNavMultiIndex = i
        }
      }
    }
  }
  return nonEmptyLevelThreeNavMultiIndex
}
