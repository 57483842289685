import { useEffect, useCallback, useState, useRef } from "react"
import { staticClasses } from "@/constants"
import { isMobile } from "react-device-detect"
import styles from "@/components/Shared/HeaderNavTabs/index.module.scss"
import { preventBodyScrollTab } from "@/utils/helper"
import { addAnalyticsOnClose } from "@/components/Shared/HeaderNavTabs/analytics"
import useSmartCrop from "@/hooks/useSmartCrop"
import TabPanel from "@/components/Shared/HeaderNavTabs/TabPanel/index"
import { checkSingleNonEmptyLevelThreeNavMulti } from "@/components/Shared/HeaderNavTabs/utils.js"

const HeaderNavTabsMobileV3 = props => {
  const {
    data,
    columnClassNames = "",
    eventHandler: {
      store: { isOpen },
      callbacks: { toggleMenu = () => {}, setMainCategory = () => {} },
    },
  } = props

  const iconCloseMedium = "/icons/close-medium.svg"
  const [navOpen, setNavOpen] = useState("")
  const smartCrop = useSmartCrop()
  const [selectedTab, setTab] = useState("")
  const [darkThemeFlag, setDarkThemeFlag] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [selectedId, setSelectedId] = useState(null)
  const [selectedNavTabIndex, setSelectedNavTabIndex] = useState(null)
  const [showTab, setShowTab] = useState(false)
  const menuVisible = useRef(0)
  const dataRecursion = useCallback(data => {
    if (data && data[":itemsOrder"]) {
      data[":itemsOrder"].map((key, index) => {
        const item = data[":items"][key]
        return dataRecursion(item)
      })
    }
  }, [])

  const handleAccordionClick = (index, id, navTabIndex) => {
    setActiveIndex(activeIndex === index && selectedId === id ? null : index)
    setSelectedId(selectedId === id && activeIndex === index ? null : id)
    setSelectedNavTabIndex(
      selectedNavTabIndex === navTabIndex ? null : navTabIndex
    )
  }
  const levelOneLengthsArray = []
  const onClickNav = (data, str, navTabIndex) => {
    if (data) {
      if (selectedTab !== data["cq:panelTitle"]) {
        setTab(data["cq:panelTitle"])
      }

      if (navOpen !== data["cq:panelTitle"]) {
        setNavOpen(data["cq:panelTitle"])
        if (!isMobile) {
          setNavOpen(data["cq:panelTitle"])
        }
        setMainCategory({ item: data["cq:panelTitle"] })
        menuVisible.current = 1
        preventBodyScrollTab(true)
        setSelectedNavTabIndex(null)
        setActiveIndex(null)
        setSelectedId(null)
        if (str === "title click") {
          setShowTab(true)
          setSelectedNavTabIndex(navTabIndex)
        }
      } else {
        setShowTab(false)
        setNavOpen("")
        setTab("")
        setMainCategory({ item: "" })
        menuVisible.current = 0
        preventBodyScrollTab(false)
      }
    }
  }

  const onMenuClickEvent = e => {
    if (
      (e?.target?.classList?.contains("gbh-pro-menu__inner") ||
        (e?.target?.closest(".gbh-pro-menu__inner") === null &&
          e?.target?.closest(".gbh-full-w-menu__inner") === null &&
          e?.target?.closest(".cmp-tabs__tablist") === null &&
          !isMobile)) &&
      menuVisible.current
    ) {
      setNavOpen("")
      setTab("")
      setMainCategory({ item: "" })
      menuVisible.current = 0
      preventBodyScrollTab(false)
    }
  }
  useEffect(() => {
    if (
      document
        ?.getElementsByTagName?.("body")?.[0]
        ?.className?.includes("nav-dark-theme")
    ) {
      setDarkThemeFlag(true)
    }
  }, [])

  useEffect(() => {
    if (navOpen) {
      document.addEventListener("click", onMenuClickEvent)
    } else {
      document.removeEventListener("click", onMenuClickEvent)
    }
    return () => document.removeEventListener("click", onMenuClickEvent)
  }, [navOpen])

  useEffect(() => {
    dataRecursion(data)
  }, [data, dataRecursion])

  const getInternalLinkURLS = url => {
    if (typeof window !== "undefined") {
      return url?.startsWith("/") ||
        url?.includes(window.location.hostname) ||
        url === null
        ? false
        : true
    }
  }
  return (
    <div id="HEADER ID" className={styles.headerNavV3Wrapper}>
      <div
        className={`tabs ${
          data?.appliedCssClassNames
            ? data?.appliedCssClassNames
            : "gbh-global-tab"
        } ${columnClassNames ? columnClassNames : ""} ${isOpen ? "open" : ""}`}
      >
        <div
          className={`cmp-tabs ${darkThemeFlag ? "dark-theme" : "light-theme"}`}
        >
          <span
            className="gbh-hamber-menu gbh-close icon-Close-Medium"
            aria-label="close button"
            tabIndex="0"
            onClick={() => {
              toggleMenu()
              onClickNav({ "cq:panelTitle": navOpen })
              addAnalyticsOnClose()
            }}
          >
            <img src={iconCloseMedium} alt="" />
          </span>
          <ol
            role="tablist"
            className="cmp-tabs__tablist"
            aria-multiselectable="false"
          >
            {data[":itemsOrder"] &&
              data[":itemsOrder"].map((key, index) => {
                const item = data[":items"][key]

                return (
                  <li
                    id={key}
                    role="tab"
                    className={`cmp-tabs__tab ${
                      selectedTab == item["cq:panelTitle"]
                        ? "cmp-tabs__tab--active"
                        : ""
                    }`}
                    tabIndex="0"
                    key={`navItem-${index}`}
                    onClick={() => onClickNav(item, "title click", index)}
                  >
                    {item["cq:panelTitle"]}
                  </li>
                )
              })}
            {data?.mainTabId &&
              Object.keys(data?.mainTabId).map((key, index) => {
                return (
                  <li
                    role="tab"
                    className="cmp-tabs__tab"
                    tabIndex="0"
                    key={`navItem-${index}`}
                  >
                    {key}
                  </li>
                )
              })}
            {data[":itemsOrder"]
              ? data[":itemsOrder"]?.map((key, navTabIndex) => {
                  const element = { ...data[":items"] }[key]
                  if (element) {
                    const item =
                      element[":items"]?.globalnavigation ||
                      element[":items"][
                        Object.keys(element[":items"])?.find(key =>
                          key.includes("globalnavigation_v3")
                        )
                      ]
                    const menuType = item?.navigationMenuType
                    levelOneLengthsArray.push(
                      checkSingleNonEmptyLevelThreeNavMulti(
                        item?.levelOneNavMulti
                      )
                    )

                    if (showTab) {
                      return (
                        <div key={key + navTabIndex}>
                          <TabPanel
                            element={element}
                            handleAccordionClick={handleAccordionClick}
                            navOpen={navOpen}
                            setActiveIndex={setActiveIndex}
                            setSelectedId={setSelectedId}
                            setSelectedNavTabIndex={setSelectedNavTabIndex}
                            navTabIndex={navTabIndex}
                            staticClasses={staticClasses}
                            darkThemeFlag={darkThemeFlag}
                            menuType={menuType}
                            smartCrop={smartCrop}
                            levelOneLengthsArray={levelOneLengthsArray}
                            activeIndex={activeIndex}
                            selectedId={selectedId}
                            onClickNav={onClickNav}
                            selectedNavTabIndex={selectedNavTabIndex}
                            selectedTab={selectedTab}
                            item={item}
                            getInternalLinkURLS={getInternalLinkURLS}
                          />
                        </div>
                      )
                    }
                  }
                })
              : null}
          </ol>
        </div>
      </div>
    </div>
  )
}

export default HeaderNavTabsMobileV3
