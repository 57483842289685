import { DATA_LAYER_REGEX, PURE_DATA_FOR_STRING_REGEX } from "@/constants/regex"
import {
  getPureText,
  pushAnalyticsObjToDataLayer,
  removeAndCleanLink,
} from "@/utils/helper"

export const addAnalyticsOnClose = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: "main dropdown menu:products:close",
    eventMsg: "n/a",
    eventName: "main dropdown menu:products:close",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "products > close",
    internalLinkPosition: "global header:products",
    internalLinkType: "global header:navigation",
    internalLinkURL: `${window.origin}${location.pathname}`,
    internalLinkZoneName: "main dropdown menu:products",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
  })
}

export const servicesAnalyticsClick = data => {
  const { text = "", link = "", Leveltwo = "", L1 = "", imgcheck = "" } = data
  const textcont = getPureText(text)
    ?.toLowerCase()
    ?.replace(DATA_LAYER_REGEX, "&")
    ?.trim()
  const l2DatalayerReady = getPureText(Leveltwo)
    ?.toLowerCase()
    ?.replace(DATA_LAYER_REGEX, "&")
    ?.trim()

  const l1DatalayerReady = getPureText(L1)
    ?.replace(DATA_LAYER_REGEX, "&")
    ?.toLowerCase()
    ?.trim()
  const url = link
    ? `${
        link.indexOf("http") === -1 ? window.location.origin : ""
      }${removeAndCleanLink(link)}`
    : "n/a"
  let internallink
  if (L1) {
    internallink = `${l1DatalayerReady} > ${l2DatalayerReady} > ${textcont}`
  } else if (imgcheck) {
    internallink = `${l2DatalayerReady} > img_${textcont}`
  } else {
    internallink = `${l2DatalayerReady} > ${textcont}`
  }

  const eventInfo = {
    clickInternalLinks:
      url?.startsWith("/") ||
      url?.includes(window.location.hostname) ||
      url === null
        ? "true"
        : "false",
    eventAction: "main dropdown menu",
    eventName: "main dropdown menu",
    eventType: "global navigation",
    internalLinkName: getPureText(internallink)?.replace(
      PURE_DATA_FOR_STRING_REGEX,
      ""
    ),
    internalLinkPosition: "global header",
    internalLinkType: "global header:navigation",
    internalLinkURL: url,
    internalLinkZoneName: "global header",
  }
  pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
}
